@import "./fonts.css";
$off-white: #f9f8f5ff;
$tusk: #e9f7cf;
$aquamarine: #55fac8;
$radical-red: #f82a6b;
$green-yellow: #b5fb1f;
$jaffa: #f56d48;
$aero-blue: #acfbe0;
$prelude: #c7c0e5;
$apricot: #ef8c65;
$screamin-green: #3ffc9d;
$rajah: #f9c26a;
$ball-colors: $aquamarine, $radical-red, $green-yellow, $jaffa, $tusk,
  $aero-blue, $prelude, $apricot, $screamin-green, $rajah;

body {
  font-family: "FiraSansExtraCondensed", sans-serif;
  font-size: 10pt;
  overflow: hidden;
  background-color: $off-white;
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

.root-page {
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

@for $i from 1 through 20 {
  .ball:nth-child(#{$i}) {
    position: absolute;
    background-color: nth($ball-colors, ($i - 1) % 10 + 1);
    border-radius: 50%;
    animation: float-and-change-size 50s ease-in-out infinite;
    animation-delay: ($i * 0.4s);
    width: 50px;
    height: 50px;
    top: random(100) * 1.2vh;
    left: random(100) * 1vw;
    filter: blur(1.5px);
    will-change: transform;
  }
}

@keyframes float-and-change-size {
  0%,
  100% {
    transform: translateY(0) rotate(0deg) scale(1); // Start and end at a larger scale
  }
  25%,
  75% {
    transform: translateY(-100px) rotate(180deg) scale(2.5);
  }
  50% {
    transform: translateY(0) rotate(360deg) scale(1); // Scale down in the middle of the animation
  }
}

.logo {
  position: relative;
  z-index: 1;
}

.coming-soon {
  font-size: 4em; /* Adjust size as needed */
  color: #7027f8; /* Adjust color as needed */
  text-align: center; /* Center the text */
}

.logo {
  width: 50%; /* Adjust size as needed */
  max-height: 35vh; /* Add this line */
  margin: auto;
  display: block; /* Add this line */
}

.admin-page {
  margin: 20px; /* Adjust as needed */
}

.table-name {
  margin: 5px 5px 10px 5px; // Top margin is 5px, right margin is 10px, bottom margin is 10px, left margin is 0
  padding: 10px;
  background-color: #e0ebdb;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: bold;

  &:first-child {
    margin-left: 0; // Left margin is 0 for the first table name
  }

  &:hover {
    background-color: #bdfdd8;
  }
}

.table-name.selected {
  background-color: #0ffbb4;
}

table {
  text-align: left;
}

.lds-spinner,
.lds-spinner div,
.lds-spinner div:after {
  box-sizing: border-box;
}
.lds-spinner {
  color: currentColor;
  display: inline-block;
  position: relative;
  width: 20px; /* 75% smaller */
  height: 20px; /* 75% smaller */
}
.lds-spinner div {
  transform-origin: 10px 10px; /* 75% smaller */
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 0.8px; /* 75% smaller */
  left: 9.2px; /* 75% smaller */
  width: 1.6px; /* 75% smaller */
  height: 4.4px; /* 75% smaller */
  border-radius: 20%;
  background: currentColor;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
